import { useRouter } from 'next/router';
import React, { useEffect, useRef } from 'react';
import LoadingBar from 'react-top-loading-bar';

const TopLoadingBar = () => {
    const loadingRef = useRef(null);
    const router = useRouter();

    useEffect(() => {
        const handleRouteChange = () => {
            loadingRef.current.continuousStart();
        };
    
        const handleRouteComplete = () => {
            loadingRef.current.complete();
        };
        router.events.on('routeChangeStart', handleRouteChange);
        router.events.on('routeChangeComplete', handleRouteComplete);

        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
            router.events.off('routeChangeComplete', handleRouteComplete);

            handleRouteComplete();
        };

    }, [router.events]);
    
    return (
        <LoadingBar
            color='#676BD2'
            height={ 3 }
            shadow={ false }
            ref={ loadingRef }
        />
    );
};

export default TopLoadingBar;
