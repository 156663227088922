import { useRouter } from 'next/router';
import React from 'react';
import { SwitchTransition, Transition } from 'react-transition-group';

const TIMEOUT = 250;

const transitionStyles = {
    entering: {
        opacity: 0,
        transform: 'translateY(-40px)'
    },
    entered: {
        transition: `opacity ${ TIMEOUT }ms, transform ${ TIMEOUT }ms`,
        opacity: 1,
        transform: 'translateY(0px)'
    },
    exiting: {
        transition: `opacity ${ TIMEOUT }ms, transform ${ TIMEOUT}ms`,
        opacity: 0,
        transform: 'translateY(-40px)'
    }
};

const AnimatedPage = ({ children }) => {
    const { pathname } = useRouter();

    return (
        <SwitchTransition
            mode='out-in'
        >
            <Transition
                key={ pathname }
                timeout={ TIMEOUT }
            >
                { status => (
                    <div
                        style={ { ...transitionStyles[status] } }
                    >
                        { children }
                    </div>
                ) }
            </Transition>
        </SwitchTransition>
    );
};

export default AnimatedPage;
